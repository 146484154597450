<nav class="navbar navbar-expand-lg fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">HARPAZAR</a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Anasayfa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#entegrasyonlar">Entegrasyonlar</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#fiyatlar">Fiyatlar</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#iletisim">İletişim</a>
        </li>
      </ul>
      <div class="d-flex">
        <button
          class="btn btn-light me-2"
          type="button"
          (click)="trialCreation()"
        >
          Deneme Hesap Oluştur
        </button>
        <button class="btn btn-light" type="button" (click)="sigIn()">
          Giriş Yap
        </button>
      </div>
    </div>
  </div>
</nav>
<section
  class="hero section-padding text-center"
  style="background: linear-gradient(45deg, #40e0d0, #00bfff); color: white"
>
  <div class="container">
    <h1 style="margin-top: 50px">HARPAZAR'a Hoşgeldiniz</h1>
    <p>Gelişmiş Entegrasyon ve Fiyatlandırma Çözümlerimizle Tanışın</p>
  </div>
</section>

<section class="section-padding" id="entegrasyonlar">
  <div class="container">
    <div class="section-header text-center">
      <h3>Entegrasyonlar</h3>
      <span class="line"></span>
      <p>Entegre edebileceğiniz sistemler ve çözümler</p>
      <span class="line"></span>
      <h6>Pazaryeri Entegrasyonları</h6>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Trendyol Pazaryeri Entegrayonu</h5>
            <p class="card-text">
              Trendyol pazaryeri entegrayonu ile ürün, stok, sipariş, iade ve
              fatura süreçlerinizi yönetebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Hepsiburada Pazaryeri Entegrayonu</h5>
            <p class="card-text">
              Hepsiburada pazaryeri entegrayonu ile ürün, stok, sipariş, iade ve
              fatura süreçlerinizi yönetebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">N11 Pazaryeri Entegrayonu</h5>
            <p class="card-text">
              N11 pazaryeri entegrayonu ile ürün, stok, sipariş, iade ve fatura
              süreçlerinizi yönetebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-header text-center">
      <span class="line"></span>
      <h6>Fatura Entegrasyonları</h6>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Digital Planet Sovos Fatura Entegrasyonu</h5>
            <p class="card-text">
              Digital Planet Sovos Fatura entegrayonu ile siparişleriniz için
              fatura kesilir ve takip edebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Nilvera Fatura Entegrayonu</h5>
            <p class="card-text">
              Nilvera Fatura entegrayonu ile siparişleriniz için fatura kesilir
              ve takip edebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">QNB Fatura Entegrayonu</h5>
            <p class="card-text">
              QNB Fatura entegrayonu ile siparişleriniz için fatura kesilir ve
              takip edebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">EDM Fatura Entegrayonu</h5>
            <p class="card-text">
              EDM fatura entegrayonu ile siparişleriniz için fatura kesilir ve
              takip edebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-padding" id="fiyatlar">
  <div class="container">
    <div class="section-header text-center">
      <h3>Fiyatlar</h3>
      <span class="line"></span>
      <p>Plan ve fiyatlarımız</p>
      <p style="color: green">KDV YOK</p>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="pricing-table">
          <h4>DEMO(1 AY)</h4>
          <div class="price">₺0</div>
          <ul>
            <li>Pazaryeri Entegrasyon</li>
            <li>Fatura Entegrasyonu</li>
            <li>Otomatik Fiyatlandırma</li>
            <li>Analiz</li>
            <li>Ürün Limiti:1000</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="pricing-table">
          <h4>Uygun Paket</h4>
          <div class="price">₺3.000/yıllık</div>
          <ul>
            <li>Pazaryeri Entegrasyon</li>
            <li>Fatura Entegrasyonu</li>
            <li>Otomatik Fiyatlandırma</li>
            <li>Analiz</li>
            <li>Ürün Limiti:1000</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="pricing-table">
          <h4>Uzman Paket</h4>
          <div class="price">₺7.000/yıllık</div>
          <ul>
            <li>Pazaryeri Entegrasyon</li>
            <li>Fatura Entegrasyonu</li>
            <li>Otomatik Fiyatlandırma</li>
            <li>Analiz</li>
            <li>Ürün Limiti:2500</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="pricing-table">
          <h4>Full Paket</h4>
          <div class="price">₺13.000/yıllık</div>
          <ul>
            <li>Pazaryeri Entegrasyon</li>
            <li>Fatura Entegrasyonu</li>
            <li>Otomatik Fiyatlandırma</li>
            <li>Analiz</li>
            <li>Ürün Limiti:Sınırsız</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-padding" id="iletisim">
  <div class="container">
    <div class="section-header text-center">
      <h3>İletişim</h3>
      <span class="line"></span>
      <p>Form kısmından bizimle iletişime geçebilirsiniz.</p>
    </div>
    <div class="section-content">
      <div class="row">
        <div class="col-lg-8">
          <form class="theme-form" [formGroup]="form">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Adınız Soyadınız</mat-label>
                  <input matInput formControlName="fullName" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Mail</mat-label>
                  <input matInput formControlName="email" />
                </mat-form-field>
              </div>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Konu</mat-label>
              <input matInput formControlName="subject" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mesajınız</mat-label>
              <textarea matInput formControlName="message" cols="3"></textarea>
            </mat-form-field>
            <button
              class="btn-grad w-100 text-uppercase"
              (click)="sendMessage(form.value)"
            >
              Gönder
            </button>
          </form>
        </div>
        <div class="col-lg-4">
          <div class="contact-info white">
            <div class="contact-item media">
              <i class="fa fa-map-marker-alt media-left media-right-margin"></i>
              <div class="media-body">
                <p class="text-uppercase">Adres</p>
                <p class="text-uppercase">Niğde Teknopark</p>
              </div>
            </div>
            <div class="contact-item media">
              <i class="fa fa-mobile media-left media-right-margin"></i>
              <div class="media-body">
                <p class="text-uppercase">Telefon</p>
                <p class="text-uppercase">
                  <a href="tel:+905456644527">0535-029-24-35</a>
                </p>
              </div>
            </div>
            <div class="contact-item media">
              <i class="fa fa-envelope media-left media-right-margin"></i>
              <div class="media-body">
                <p class="text-uppercase">MAİL</p>
                <p>
                  <a href="mailto:gokhannaipli@gmail.com"
                    >info&#64;harpazar.com</a
                  >
                </p>
              </div>
            </div>
            <div class="contact-item media">
              <i class="fa fa-clock media-left media-right-margin"></i>
              <div class="media-body">
                <p class="text-uppercase">Çalışma Saatleri</p>
                <p class="text-uppercase">Pazartesi-Cumartesi 08:00-18:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<footer class="site-base-footer section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h5>HARPAZAR</h5>
        <p>Entegre çözümlerimizle işinizi büyütün</p>
      </div>
      <div class="col-md-4">
        <h5>Bağlantılar</h5>
        <ul class="list-unstyled">
          <li><a href="#">Anasayfa</a></li>
          <li><a href="#entegrasyonlar">Entegrasyonlar</a></li>
          <li><a href="#fiyatlar">Fiyatlar</a></li>
          <li><a href="#iletisim">İletişim</a></li>
        </ul>
      </div>
      <div class="col-md-4">
        <h5>İletişim</h5>
        <ul class="list-unstyled">
          <li><i class="fa fa-map-marker-alt"></i> Niğde Teknopark</li>
          <li>
            <i class="fa fa-phone"></i>
            <a href="tel:+905456644527">0535-029-24-35</a>
          </li>
          <li>
            <i class="fa fa-envelope"></i>
            <a href="mailto:info@harpazar.com">info&#64;harpazar.com</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center">
      <p>&copy; 2024 HARPAZAR. Tüm Hakları Saklıdır.</p>
    </div>
  </div>
</footer>
