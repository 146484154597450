import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResultDto } from 'src/app/core/dtos/api';
import { SendEmailRequestDto } from 'src/app/core/dtos/site';
import { SendEmailService } from 'src/app/core/services/general';
import { MessageService } from 'src/app/core/services/message';
import { httpErrorResponseUtil, ROUTER_UTILS } from 'src/app/core/utils';

@Component({
  selector: 'app-site-base',
  templateUrl: './site-base.component.html',
  styleUrls: ['./site-base.component.scss'],
})
export class SiteBaseComponent implements OnInit {
  form: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private sendMailService: SendEmailService
  ) {
    this.form = this.formBuilder.group({
      fullName: [null, [Validators.required]],
      email: [null, Validators.required],
      subject: [null, Validators.required],
      message: [null, Validators.required],
    });
  }
  ngOnInit(): void {}

  sigIn() {
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.signIn}`
    );
  }

  trialCreation() {
    this.router.navigateByUrl(
      `/${ROUTER_UTILS.config.auth.root}/${ROUTER_UTILS.config.auth.trialCreation}`
    );
  }

  sendMessage(data: SendEmailRequestDto) {
    if (this.form.invalid) {
      return;
    }

    this.sendMailService.sendInfoMail(data).subscribe(
      (data: ResultDto) => {
        if (data.success) {
          this.messageService.success(data.message);
          this.form.reset();
          Object.keys(this.form.controls).forEach(key => {
            this.form.get(key).setErrors(null) ;
        });
        } else {
          for (let index = 0; index < data.errors.length; index++) {
            this.messageService.error(data.errors[index]);
          }
        }
      },
      (error: HttpErrorResponse) => {
        var errorStr = httpErrorResponseUtil(error);
        this.messageService.error(errorStr);
      }
    );
  }
}
