import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/apis';
import { ResultDto } from 'src/app/core/dtos/api';
import { SendEmailRequestDto } from 'src/app/core/dtos/site';
import { API_ROUTER_UTILS } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  constructor(private apiService: ApiService) {}

  sendInfoMail(data: SendEmailRequestDto): Observable<ResultDto> {
    return this.apiService.post<ResultDto>(
      API_ROUTER_UTILS.url.sendMail.sendInfoMail,
      data,
      null,
      {}
    );
  }
}
